import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useResendOtpWhatsapp } from 'src/hooks';
import { useAddWalletXellarWhatsapp, useVerifyAddWalletXellar } from 'src/hooks/useWallet';
import { getClipboardText } from 'src/utils/func';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import ImageC from '../Image';
import Input from '../Input';
import InputOTP from '../InputOTP';

import classes from './index.module.less';

interface Props {
	// onSignUpClick?: () => void;
	onBack?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardAddWalletByWhatsapp = ({
	// onSignUpClick,
	onBack,
	onClose,
	onSuccess,
	updateLoadingState,
}: Props) => {
	const { mutate: add, data: dataAdd, isLoading: isLoadingAdd } = useAddWalletXellarWhatsapp();
	const { mutate: verifyOtp, isLoading: isLoadingVerifyOtp } = useVerifyAddWalletXellar();
	const {
		mutate: resendOtp,
		data: dataResendOtp,
		isLoading: isLoadingResendOtp,
		reset: resetResendOtp,
	} = useResendOtpWhatsapp();

	const resLogin = dataAdd?.data?.data;
	const resResendOtp = dataResendOtp?.data?.data;

	const isLoading = isLoadingAdd || isLoadingVerifyOtp;

	const otpNumberOfDigits = 6;
	const otpExpiryTime = parseInt(process.env.NEXT_PUBLIC_OTP_EXPIRED_DURATION as string); // in seconds

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [formData, setFormData] = useState<{
		code: string;
		number: string;
	}>({
		code: '+62',
		number: '',
	});
	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));
	const [otpExpiredCountdown, setOtpExpiredCountdown] = useState<number>(0);

	const finalOtp = otp.join('');

	const otpExpiredCountdownRef = useRef(otpExpiryTime);

	let otpExpiredCountdownInterval: any = null;
	const stopOtpExpiredCountdown = () => {
		if (otpExpiredCountdownInterval !== null) {
			clearInterval(otpExpiredCountdownInterval);
			otpExpiredCountdownInterval = null;
		}
	};
	const startOtpExpiredCountdown = () => {
		stopOtpExpiredCountdown();

		otpExpiredCountdownRef.current = otpExpiryTime;
		setOtpExpiredCountdown(otpExpiredCountdownRef.current);

		otpExpiredCountdownInterval = setInterval(() => {
			if (otpExpiredCountdownRef.current === 0) {
				stopOtpExpiredCountdown();
				return;
			}
			otpExpiredCountdownRef.current = otpExpiredCountdownRef.current - 1;
			setOtpExpiredCountdown(otpExpiredCountdownRef.current);
		}, 1000);
	};

	const getCountdownTimer = () => {
		const min: string = Math.floor(otpExpiredCountdown / 60).toString();
		let sec: string = (otpExpiredCountdown % 60).toString();
		if (sec.length === 1) {
			sec = '0' + sec;
		}
		return min + ':' + sec;
	};

	const validateFormData = (data: any) => {
		if (
			data == null ||
			Object.values(data ?? [])?.find((val: any) => val.length < 3) !== undefined
		) {
			return false;
		}

		return true;
	};

	const handleFormInputChange = (e: any) => {
		const name = e.target.name;
		let value = e.target.value;
		// if (name === 'username') {
		// value = value.replace(/[^0-9a-z]/gi, '');
		// }
		// console.log(value);
		if (name === 'code') {
			if (value.length > 5) {
				return;
			} else {
				// Removing every non-digit character except a plus (+) sign
				value = value.replace(/[^\d\+]+/g, '').replace(/(.)\++/g, '$1');
			}
		} else if (name === 'number') {
			if (value.length > 15) {
				return;
			}
		}
		setFormData({ ...formData, [name]: value });
	};

	const handlePasteFromClickboardClick = async () => {
		let clipboardText = await getClipboardText();
		clipboardText = clipboardText.replace(/\s/g, '');

		setOtp([
			clipboardText[0] ?? '',
			clipboardText[1] ?? '',
			clipboardText[2] ?? '',
			clipboardText[3] ?? '',
			clipboardText[4] ?? '',
			clipboardText[5] ?? '',
		]);
	};

	const handleConnectClick = (e: any) => {
		e.preventDefault();

		const phoneNumber = `${formData.code}${formData.number}`.replaceAll('+', '');

		add(
			{
				reference: phoneNumber,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						resetResendOtp();
						startOtpExpiredCountdown();
						setStep(2);
					} else {
						notify(res?.data?.message ?? 'Failed to add wallet', 'error');
					}
				},
			},
		);
	};

	const handleResendOtpClick = () => {
		const verifyToken = resResendOtp?.verify_token ?? resLogin?.verify_token;

		resendOtp(
			{ verify_token: verifyToken, creo_id: resLogin.creo_id },
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						startOtpExpiredCountdown();
					} else {
						notify(res?.data?.message ?? 'Failed to resend otp', 'error');
					}
				},
			},
		);
	};

	const handleVerifyOtpClick = (e: any) => {
		e.preventDefault();

		if (finalOtp.length === otpNumberOfDigits) {
			const phoneNumber = `${formData.code}${formData.number}`.replaceAll('+', '');
			const verifyToken = resResendOtp?.verify_token ?? resLogin?.verify_token;

			verifyOtp(
				{
					creo_id: resLogin.creo_id,
					verify_token: verifyToken,
					otp: finalOtp,
					phone_number: phoneNumber,
				},
				{
					onSuccess: (res: any) => {
						if (res.status === 200) {
							notify('Add wallet success.');

							onSuccess?.();
						} else {
							notify(res?.data?.message ?? 'Failed to add wallet', 'error');
						}
					},
				},
			);
		}
	};

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	useEffect(() => {
		setButtonDisabled(true);
		setOtp(new Array(otpNumberOfDigits).fill(''));
	}, [step]);

	useEffect(() => {
		if (validateFormData(formData)) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [formData]);

	useEffect(() => {
		if (finalOtp.length === otpNumberOfDigits) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [finalOtp]);

	return (
		<CardAuth>
			<div className={classes.header}>
				<ImageC
					alt="icon back"
					className="cursor-pointer"
					src="/icons/icon-back-white.svg"
					width={16}
					height={16}
					onClick={() => {
						if (step > 1) {
							setStep(step - 1);
						} else {
							onBack?.();
						}
					}}
				/>
				<ImageC
					alt="icon close"
					className="cursor-pointer"
					src="/icons/icon-close-white.svg"
					width={16}
					height={16}
					onClick={onClose}
				/>
			</div>

			<h1 className={classes.title}>Add new CREO Wallet</h1>

			{step === 1 && (
				<>
					<form className={classNames(classes.form, 'pt-4 mb-0')} onSubmit={handleConnectClick}>
						<Row gutter={[30, 12]}>
							<Col span={24}>
								<div className={classes['phone-number']}>
									<div
										className={classes.code}
										style={{
											width: `calc(${formData?.code?.toString()?.length ?? 0}ch + 38px)`,
										}}
									>
										<Input
											name="code"
											placeholder="+62"
											// defaultValue={'+62'}
											value={formData.code}
											onChange={handleFormInputChange}
										/>
									</div>
									<div className={classes.line}></div>
									<div className={classes.number}>
										<Input
											className={classes.number}
											type="number"
											name="number"
											placeholder=""
											value={formData.number}
											onChange={handleFormInputChange}
										/>
									</div>
								</div>
							</Col>
						</Row>
						<div className={classNames(classes['create-account'], 'mt-4 text-center')}>
							Enter your Whatsapp number above and we will send you the verification number
						</div>
						<Button
							className={classNames(classes['submit-btn'], 'w-100 mb-4')}
							type="submit"
							loading={isLoading}
							// disabled={buttonDisabled}
							style={{ marginTop: 73 }}
						>
							Connect Creoplay
						</Button>
						{/* <div className={classNames(classes['create-account'], 'mt-0')}>
							<span>Lost your number?</span>
							<a onClick={() => onSignUpClick?.()}>Recover Account</a>
						</div> */}
						{/* <div className={classes.agreement}>
							By signing up you are agreeing to <br />
							our <a>Terms of Use</a> and our <a>Privacy Policy</a>
						</div> */}
					</form>
				</>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'], 'pt-4')}
					onSubmit={handleVerifyOtpClick}
				>
					<InputOTP otp={otp} setOtp={setOtp} />

					<div className={classNames(classes.label, 'mt-4')}>
						<div>
							Enter the verification code that has been sent to{' '}
							<span className="fw-500">{formData.code + formData.number}</span>
						</div>
					</div>
					{finalOtp.length === otpNumberOfDigits ? (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							type="submit"
							loading={isLoading}
							disabled={buttonDisabled}
						>
							Continue
						</Button>
					) : (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							onClick={handlePasteFromClickboardClick}
						>
							Paste from Clipboard
						</Button>
					)}

					<div className={classNames(classes.label, 'fw-400')}>
						<div>
							Expires in <span className="fw-500">{getCountdownTimer()}</span>
						</div>
						<a
							className={classNames({ disabled: isLoadingResendOtp || otpExpiredCountdown !== 0 })}
							onClick={handleResendOtpClick}
						>
							Resend code
						</a>
					</div>
				</form>
			)}
		</CardAuth>
	);
};

export default CardAddWalletByWhatsapp;
