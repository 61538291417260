import { useEffect } from 'react';
import CardAuth from '.';
import classNames from 'classnames';

import { useLoginTelegram } from 'src/hooks';

import ImageC from '../Image';
import { IReloginData } from '../ModalWallet';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	onBack?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
	reloginData: IReloginData | null;
}

const CardReloginWalletByTelegram = ({
	onBack,
	onClose,
	updateLoadingState,
	reloginData,
}: Props) => {
	// const { setUser } = useAuthReducer();

	const {
		// mutate: login,
		// data: dataLogin,
		isLoading: isLoadingLogin,
	} = useLoginTelegram();

	const isLoading = isLoadingLogin;

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	return (
		<CardAuth>
			<div className={classes.header}>
				<ImageC
					alt="icon back"
					className="cursor-pointer"
					src="/icons/icon-back-white.svg"
					width={16}
					height={16}
					onClick={onBack}
				/>
				<ImageC
					alt="icon close"
					className="cursor-pointer"
					src="/icons/icon-close-white.svg"
					width={16}
					height={16}
					onClick={onClose}
				/>
			</div>

			<h1 className={classes.title}>Wallet Login</h1>

			<form
				className={classNames(classes.form, classes['form-otp'], 'pt-2')}
				// onSubmit={handleVerifyOtpClick}
			>
				<div className={classNames(classes.label)}>
					<div>
						You have been inactive for 7 or more days. <br />
						Please confirm connection for <br />
						{reloginData?.phone_number}
					</div>
				</div>
				<div className="w-100 mt-5 pt-5 d-flex justify-content-center">
					<Spinner className="" color="#FFFFFFCC" width={7} size={63} />
				</div>
			</form>
		</CardAuth>
	);
};

export default CardReloginWalletByTelegram;
