import { useEffect, useState } from 'react';
import CardAuth from '.';

import { useAddWalletXellarTelegram } from 'src/hooks/useWallet';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import ImageC from '../Image';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	onBack?: () => void;
	onClose?: () => void;
	onLoginWhatsappClick?: () => void;
	onLoginTelegramClick?: () => void;
	onLoginEmailClick?: () => void;
	// onSignUpClick?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardAddWallet = ({
	onBack,
	onClose,
	onLoginWhatsappClick,
	// onLoginTelegramClick,
	onLoginEmailClick,
	// onSignUpClick,
	onSuccess,
	updateLoadingState,
}: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { mutate: addTelegram } = useAddWalletXellarTelegram();

	const handleLoginWhatsappClick = () => {
		onLoginWhatsappClick?.();
	};

	const handleLoginTelegramClick = () => {
		// onLoginTelegramClick?.();

		setIsLoading(true);

		(window as any)?.Telegram?.Login?.auth(
			{ bot_id: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID, request_access: true },
			(data: any) => {
				console.log(data);
				if (!data) {
					// authorization failed
					notify('Telegram authorization cancelled', 'error');
					setIsLoading?.(false);
					return;
				}

				addTelegram(
					{ ...data },
					{
						onSuccess: (res: any) => {
							if (res.status === 200) {
								notify('Success add wallet');
								onSuccess?.();
							} else {
								notify(res?.data?.message ?? 'Failed to add wallet', 'error');
							}
						},
					},
				);
				setIsLoading(false);
			},
		);
	};

	const handleLoginEmailClick = () => {
		onLoginEmailClick?.();
	};

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	return (
		<Spinner spinning={isLoading}>
			<CardAuth showCreoplayImage={false}>
				<div className={classes.header}>
					{onBack && (
						<ImageC
							alt="icon back"
							className="cursor-pointer"
							src="/icons/icon-back-white.svg"
							width={16}
							height={16}
							onClick={onBack}
						/>
					)}
					{onClose && (
						<ImageC
							alt="icon close"
							className="ml-auto cursor-pointer"
							src="/icons/icon-close-white.svg"
							width={16}
							height={16}
							onClick={onClose}
						/>
					)}
				</div>

				<h1 className={classes.title}>
					Add new
					<br />
					CREO Wallet
				</h1>
				<div className={classes['login-options']}>
					<Button
						className={classes['login-opt-button']}
						onClick={() => handleLoginWhatsappClick()}
					>
						<ImageC alt="icon wallet" src="/icons/whatsapp.svg" height={20} width={20} />
						<span>Continue with Whatsapp</span>
					</Button>
					<Button
						className={classes['login-opt-button']}
						onClick={() => handleLoginTelegramClick()}
					>
						<ImageC alt="icon wallet" src="/icons/telegram.svg" height={20} width={20} />
						<span>Continue with Telegram</span>
					</Button>
					<Button className={classes['login-opt-button']} onClick={() => handleLoginEmailClick()}>
						<ImageC alt="icon wallet" src="/icons/mail.svg" height={20} width={20} />
						<span>Continue with Email</span>
					</Button>
				</div>
				{/* <div className={classes['create-account']}>
					<span>Don’t have an account yet?</span>
					<a onClick={() => onSignUpClick?.()}>Sign Up</a>
				</div>
				<div className={classes.agreement}>
					By signing up you are agreeing to <br />
					our <a>Terms of Use</a> and our <a>Privacy Policy</a>
				</div> */}
			</CardAuth>
		</Spinner>
	);
};

export default CardAddWallet;
