import { createContext, ReactNode, useContext, useState } from 'react';

const ModalWalletContext = createContext<any>(null);

interface IModalWalletTypes {
	types:
		| 'select-wallet'
		| 'add-wallet'
		| 'add-by-email'
		| 'add-by-whatsapp'
		| 'add-by-telegram'
		| 'relogin-email'
		| 'relogin-whatsapp'
		| 'relogin-telegram';
}

export interface IModalWallet {
	isModalWalletOpen: boolean;
	closeModalWallet: () => void;
	openModalWallet: (type?: string) => void;
	initType: IModalWalletTypes['types'] | null;
	type: IModalWalletTypes['types'];
	setType: (type: string) => void;
}

interface Props {
	children: ReactNode;
}

export const ModalWalletContextProvider = ({ children }: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [initType, setInitType] = useState<IModalWallet['type'] | null>(null);
	const [type, setType] = useState<IModalWallet['type']>('select-wallet');

	const open = (newType?: IModalWallet['type']) => {
		setInitType(newType ?? null);
		setType(newType ?? 'select-wallet');
		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
	};

	return (
		<ModalWalletContext.Provider
			value={
				{
					isModalWalletOpen: isOpen,
					closeModalWallet: close,
					openModalWallet: open,
					initType,
					type,
					setType,
				} as IModalWallet
			}
		>
			{children}
		</ModalWalletContext.Provider>
	);
};

export function useModalWalletContext() {
	return useContext(ModalWalletContext) as IModalWallet;
}
