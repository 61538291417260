import { useQuery } from 'react-query';

import { useWeb3Context } from 'src/context/web3';
import web3config from 'src/utils/web3config';

import { useAPICaller } from './useAPICaller';

export const queryKeyGetMaintenanceStatus = 'queryKeyGetMaintenanceStatus';
export const queryKeyCreoEngineData = 'queryKeyCreoEngineData';

export const useGetMaintenanceStatus = (enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGetMaintenanceStatus],
		() => {
			return fetchAPI({
				endpoint: '/maintenance/status',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetCreoEngineData = (enabled = true) => {
	const { fetchAPI } = useAPICaller();

	const { pancakeswapUrl } = web3config;

	return useQuery(
		[queryKeyCreoEngineData],
		() => {
			return fetchAPI({
				endpoint: pancakeswapUrl,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetBnbData = (enabled = true) => {
	const { fetchAPI } = useAPICaller();
	const { chainId: walletChainId, xellarChainId } = useWeb3Context();

	const currentChainId = Number(walletChainId || xellarChainId || 0);
	const id = currentChainId === 1 ? 'ethereum' : 'binancecoin';

	https: return useQuery(
		['queryKeyBnbData'],
		() => {
			return fetchAPI({
				endpoint: `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};
