/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable max-len */
import { ComponentType, useEffect, useState } from 'react';
import { FacebookProvider } from 'react-facebook';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { StyleProvider } from '@ant-design/cssinjs';
import { GoogleOAuthProvider } from '@react-oauth/google';

import MainLayout from 'src/components/Layout/MainLayout';
import MaintenanceChecker from 'src/components/MaintenanceChecker';
import ModalWallet from 'src/components/ModalWallet';
import TelegramLoginWidget from 'src/components/TelegramLoginWidget';
import XellarPopupConfirmation from 'src/components/Xellar/PopupConfirmation';
import { ModalWalletContextProvider } from 'src/context/modal';
import { Web3ContextProvider } from 'src/context/web3';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { wrapper } from 'src/redux/store';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/styles/index.less';

export type NextPageWithLayout = NextPage & {
	getLayout?: ComponentType<any>;
	layoutProps?: any;
};

export let noIndexNoFollow = { noindex: false, nofollow: false };
if (process.env.NEXT_PUBLIC_FLAVOR !== 'MAINNET') {
	noIndexNoFollow = { noindex: true, nofollow: true };
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const AppRoot = ({ Component, pageProps }: AppPropsWithLayout) => {
	const router = useRouter();
	const { user } = useAuthReducer();

	const [queryClient] = useState(
		() => new QueryClient({ defaultOptions: { queries: { staleTime: 60 * 1000 } } }),
	);

	const Layout = Component.getLayout ?? MainLayout;
	const layoutProps = Component.layoutProps;

	useEffect(() => {
		if (!router.isReady) return;

		if (!user && pageProps?.protectedRoute) {
			router.push('/');
		}

		if (
			user?.profile?.wallet_type === 'external' &&
			router.pathname.indexOf('/wallet-settings') > -1
		) {
			router.push('/');
		}
	}, [JSON.stringify(user), router.isReady]);

	const robots =
		process.env.NEXT_PUBLIC_FLAVOR === 'MAINNET'
			? 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
			: 'noindex, nofollow';

	return (
		<QueryClientProvider client={queryClient}>
			<Hydrate state={pageProps.dehydratedState}>
				<Web3ContextProvider>
					<ModalWalletContextProvider>
						<GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}>
							<FacebookProvider appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string}>
								<StyleProvider layer>
									<Layout {...pageProps} {...layoutProps} router={router}>
										<NextSeo title="CreoPlay: Play the Best WEB3 Games" />
										<Head>
											<meta name="robots" content={robots} />
											<meta
												name="viewport"
												// eslint-disable-next-line max-len
												content="width=device-width, minimum-scale=1, maximum-scale=3, initial-scale=1, shrink-to-fit=no, height=device-height, viewport-fit=cover"
											/>
											<script
												async
												src="https://www.googletagmanager.com/gtag/js?id=G-DC9VYC06F1"
											></script>
											<script>
												{`
													window.dataLayer = window.dataLayer || [];
													function gtag(){dataLayer.push(arguments);}
													gtag('js', new Date());
													gtag('config', 'G-DC9VYC06F1');
												`}
											</script>
											<script id="gtm" {...({ strategy: 'afterInteractive' } as any)}>
												{`
													(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
													new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
													j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
													'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
													})(window,document,'script','dataLayer','GTM-TJW8466H');
												`}
											</script>
										</Head>
										<MaintenanceChecker />
										<ModalWallet />
										<XellarPopupConfirmation />
										<TelegramLoginWidget />
										<Component {...pageProps} router={router} />
									</Layout>
								</StyleProvider>
							</FacebookProvider>
						</GoogleOAuthProvider>
					</ModalWalletContextProvider>
				</Web3ContextProvider>
			</Hydrate>
		</QueryClientProvider>
	);
};

export default wrapper.withRedux(AppRoot);
