/* eslint-disable no-tabs */
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { notify } from 'src/utils/notify';

import useAuthReducer from './reducers/useAuthReducer';
import useAPICaller from './useAPICaller';
import { useGetProfileData } from './useAuth';

export const queryKeyGetWalletList = 'queryKeyGetWalletList';

export const useGetWalletList = (enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGetWalletList],
		() => {
			return fetchAPI({
				endpoint: '/user/wallet',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled },
	);
};

export const useSetCurrentWallet = () => {
	const { fetchAPI } = useAPICaller();
	const botUsername = process.env.NEXT_PUBLIC_TELEGRAM_BOT_USERNAME;

	return useMutation(
		(payload: {
			force_login?: boolean; // for testing only
			wallet_address: string;
			telegram?: {
				id: string;
				first_name: string;
				// last_name: string;
				// username: string;
				// photo_url: string;
				auth_date: string;
				hash: string;
			};
		}) =>
			fetchAPI({
				endpoint: '/user/wallet/set',
				method: 'POST',
				data: {
					...(payload?.force_login ? { force_login: payload?.force_login } : {}),
					wallet_address: payload.wallet_address,
					...(payload?.telegram
						? {
								telegram: {
									data: { ...payload.telegram },
									botUsername,
								},
							}
						: {}),
				},
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Switch wallet failed',
					'error',
				);
			},
		},
	);
};

export const useVerifySetCurrentWallet = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { verify_token: string; otp: string; wallet_address: string }) =>
			fetchAPI({
				endpoint: '/user/wallet/set-and-verify-otp/xellar',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Switch wallet failed',
					'error',
				);
			},
		},
	);
};

export const useAddWalletXellarWhatsapp = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { reference: string }) =>
			fetchAPI({
				endpoint: '/user/wallet/add/xellar',
				method: 'POST',
				data: { type: 'whatsapp', ...payload },
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Add xellar wallet failed',
					'error',
				);
			},
		},
	);
};

export const useAddWalletXellarTelegram = () => {
	const queryClient = useQueryClient();
	const { user, clearUserProfile } = useAuthReducer();

	const { fetchAPI } = useAPICaller();
	const botUsername = process.env.NEXT_PUBLIC_TELEGRAM_BOT_USERNAME;

	return useMutation(
		(payload: {
			id: string;
			first_name: string;
			// last_name: string;
			// username: string;
			// photo_url: string;
			auth_date: string;
			hash: string;
		}) =>
			fetchAPI({
				endpoint: '/user/wallet/add/xellar',
				method: 'POST',
				data: {
					type: 'telegram',
					telegram: {
						data: { ...payload },
						botUsername,
					},
				},
			}),
		{
			onSuccess: () => {
				if (!user?.profile?.wallet_id) {
					clearUserProfile();
				}
				queryClient.invalidateQueries(queryKeyGetWalletList);
			},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Add xellar wallet failed',
					'error',
				);
			},
		},
	);
};

export const useAddWalletXellarEmail = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { reference: string }) =>
			fetchAPI({
				endpoint: '/user/wallet/add/xellar',
				method: 'POST',
				data: { type: 'email', ...payload },
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Add xellar wallet failed',
					'error',
				);
			},
		},
	);
};

export const useVerifyAddWalletXellar = () => {
	const queryClient = useQueryClient();
	const { user, clearUserProfile } = useAuthReducer();

	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: {
			creo_id: string;
			verify_token: string;
			otp: string;
			email?: string;
			phone_number?: string;
		}) =>
			fetchAPI({
				endpoint: '/user/wallet/verify-otp/xellar',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {
				if (!user?.profile?.wallet_id) {
					clearUserProfile();
				}
				queryClient.invalidateQueries(queryKeyGetWalletList);
			},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Add xellar wallet failed',
					'error',
				);
			},
		},
	);
};

export const useAddWalletExternal = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { wallet_address: string; name: string }) =>
			fetchAPI({
				endpoint: '/user/wallet/add/external',
				method: 'POST',
				data: { ...payload, name: '' },
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Add xellar wallet failed',
					'error',
				);
			},
		},
	);
};

export const useDeleteWallet = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { wallet_address: string }) =>
			fetchAPI({
				endpoint: '/user/wallet/remove',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Delete xellar wallet failed',
					'error',
				);
			},
		},
	);
};

export const useRenameWallet = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { wallet_address: string; name: string }) =>
			fetchAPI({
				endpoint: '/user/wallet/rename',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Delete xellar wallet failed',
					'error',
				);
			},
		},
	);
};

export const useSetWalletSubscriptionStatus = () => {
	const queryClient = useQueryClient();
	const { user, setUser } = useAuthReducer();
	// const dispatch = useDispatch();

	const { mutate: getProfileData } = useGetProfileData();

	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { wallet_address: string; subscription_ends_at: string; chain_id: number }) =>
			fetchAPI({
				endpoint: '/user/wallet/set-premium',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(queryKeyGetWalletList);

				// let newUser: IUser = { ...user };
				// delete newUser.profile;

				// dispatch(
				// setAuthUserData({
				// user: newUser,
				// }),
				// );

				getProfileData(undefined, {
					onSuccess: (res: any) => {
						if (res.status === 200 && res?.data?.data) {
							const newUserObj = {
								...user,
								profile: res.data.data,
							};
							setUser(newUserObj);
						}
					},
					onError: (/*err*/) => {
						// console.log(err);
					},
				});
			},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Set wallet subscription status failed',
					'error',
				);
			},
		},
	);
};

export const useGetRecoveryKey = (wallet_address: string) => {
	const enabled = wallet_address !== undefined && wallet_address !== null && wallet_address !== '';

	const { fetchAPI } = useAPICaller();

	return useQuery(
		['queryKeyGetRecoveryKey', wallet_address],
		() => {
			return fetchAPI({
				method: 'POST',
				endpoint: '/user/wallet/get-recovery-key',
				data: { wallet_address },
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled },
	);
};
