import { useState } from 'react';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { ConfigProvider } from 'antd/lib';
import Button from 'antd/lib/button';
import classNames from 'classnames';

import ButtonC, { ButtonBorder } from 'src/components/Button';
import ImageC from 'src/components/Image';
import { IReloginData } from 'src/components/ModalWallet';
import SearchBar from 'src/components/SearchBar';
import Skeleton from 'src/components/Skeleton';
import Spinner from 'src/components/Spinner';
import { useModalWalletContext } from 'src/context/modal';
import { useWeb3Context } from 'src/context/web3';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import useAuthAction from 'src/hooks/useAuthAction';
import { useGetWalletList, useSetCurrentWallet } from 'src/hooks/useWallet';
import { IWallet } from 'src/interface/Wallet';
import { notify } from 'src/utils/notify';

import classes from './index.module.less';

interface Props {
	onRequestRelogin?: (type: string, data: IReloginData) => void;
	onAddCreoWalletClick?: () => void;
	onAddExternalWalletClick?: () => void;
	onCloseClick?: () => void;
}

const CardSelectWallet = ({
	onRequestRelogin,
	onAddCreoWalletClick,
	// onAddExternalWalletClick,
	onCloseClick,
}: Props) => {
	const { user, clearUserProfile } = useAuthReducer();
	const { closeModalWallet } = useModalWalletContext();
	const { toggleModalExternalWallet, updateSwitchWalletAddress } = useAuthAction();
	const { chainId, xellarChainId, disconnect } = useWeb3Context();

	const currentChainId = chainId ?? xellarChainId;

	const { open } = useWeb3Modal();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');

	const selectedWalletAddress = user?.wallet_id;
	const { data: dataWalletList, isLoading: isLoadingWalletList } = useGetWalletList();
	let walletList: IWallet[] = dataWalletList?.data?.data;
	walletList = walletList?.map((wallet) => ({
		...wallet,
		icon:
			wallet.type === 'xellar' ? '/icons/icon-wallet-creo.svg' : '/icons/icon-wallet-external.svg',
		is_premium:
			wallet?.subscriptions?.find(({ chain_id }) => parseInt(chain_id) === currentChainId) !==
			undefined,
	}));

	const { mutate: switchWallet, isLoading: isLoadingSwitchWallet } = useSetCurrentWallet();

	const filteredListWallet = walletList?.filter(
		({ name, wallet_address }: IWallet) =>
			name.toLowerCase().includes(search.toLowerCase()) ||
			wallet_address.toLowerCase().includes(search.toLowerCase()),
	);

	const loading = isLoading || isLoadingSwitchWallet;

	const handleReloginTelegram = (wallet: any) => {
		setIsLoading(true);

		(window as any)?.Telegram?.Login?.auth(
			{ bot_id: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID, request_access: true },
			(dataT: any) => {
				// console.log(dataT);
				if (!dataT) {
					// authorization failed
					notify('Telegram authorization cancelled', 'error');
					setIsLoading(false);
					return;
				}

				switchWallet(
					{
						wallet_address: wallet.wallet_address,
						telegram: { ...dataT },
					},
					{
						onSuccess: (res: any) => {
							if (res.status === 200 && res?.data?.data) {
								// console.log(res);
								const switchData = res?.data?.data;

								if (!switchData?.need_verify) {
									notify('Successfully switch wallet');

									setIsLoading(false);

									clearUserProfile();

									disconnect();

									closeModalWallet();
								} else {
									handleReloginTelegram(wallet);
								}
							} else {
								// error add external wallet
								notify('Failed to switch wallet.', 'error');
							}
						},
						onError: () => {
							// console.log({ err });
							setIsLoading(false);
						},
					},
				);
			},
		);
	};

	const handleSwitchWalletXellar = (wallet: any) => {
		switchWallet(
			{
				// force_login: true,
				wallet_address: wallet.wallet_address,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200 && res?.data?.data) {
						// console.log(res);
						const switchData = res?.data?.data;

						if (!switchData?.need_verify) {
							notify('Successfully switch wallet');

							clearUserProfile();

							disconnect();

							closeModalWallet();
						} else {
							if (switchData?.type !== 'telegram') {
								// open verify switch otp layout
								onRequestRelogin?.(switchData?.type, {
									wallet_address: wallet.wallet_address,
									...switchData,
								});
							} else {
								handleReloginTelegram(wallet);
							}
						}
					} else {
						// error add external wallet
						notify('Failed to switch wallet.', 'error');
					}
				},
				onError: () => {
					// console.log({ err });
				},
			},
		);
	};

	const handleChange = (wallet: IWallet) => {
		updateSwitchWalletAddress(wallet.wallet_address);

		// Show login web3modal here if wallet is external
		if (wallet.type === 'external') {
			toggleModalExternalWallet(true, 'switch');
			disconnect();
			open();
		} else {
			handleSwitchWalletXellar(wallet);
		}
	};

	const handleAddExternalWalletClick = () => {
		disconnect();
		toggleModalExternalWallet(true, 'add');
		open();
	};

	return (
		<Spinner spinning={loading}>
			<div className={classes.card}>
				<h2 className="fs-20 fw-600">Select Wallet</h2>

				<div className={classes['search-wallet']}>
					<SearchBar
						placeholder="Search account"
						onClick={(e) => {
							e.stopPropagation();
						}}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
				<ConfigProvider wave={{ disabled: true }}>
					<div className={classes['list-wallet']}>
						{isLoadingWalletList
							? [1, 2, 3]?.map((i) => (
									// eslint-disable-next-line react/jsx-indent
									<ButtonC key={i} disabled className={classNames(classes['dropdown-item'], {})}>
										<Skeleton
											className={classNames(classes.icon, 'mr-2')}
											height={30}
											width={30}
											borderRadius="50%"
										/>
										<div className="text-single-line mr-3 w-100">
											<Skeleton
												className="text-single-line mb-1"
												height={14}
												width={'100%'}
												variant="text"
											/>
											<Skeleton
												className="fs-10 fs-lg-12 fw-400 text-grey "
												height={14}
												width={'75%'}
												variant="text"
											/>
										</div>
									</ButtonC>
								))
							: filteredListWallet?.map((wallet: IWallet) => {
									// eslint-disable-next-line max-len
									const walletAddress = wallet?.wallet_address;
									const shortWalletId =
										walletAddress !== ''
											? `${walletAddress.substring(0, 5)}...${walletAddress.substring(walletAddress.length - 4, walletAddress.length)}`
											: '';

									return (
										<ButtonC
											key={walletAddress}
											onClick={() => handleChange(wallet)}
											className={classNames(classes['dropdown-item'], {
												[classes.active]:
													walletAddress.toLowerCase() === selectedWalletAddress?.toLowerCase(),
											})}
										>
											{wallet?.icon !== '' && (
												<ImageC
													className={classNames(classes.icon, 'mr-2')}
													alt="logo"
													src={wallet?.icon ?? '/icons/creoplay.svg'}
												/>
											)}
											<div className="text-single-line mr-3">
												<div className="text-single-line">{wallet.name}</div>
												<div className="fs-10 fs-lg-12 fw-400 text-grey ">{shortWalletId}</div>
											</div>
											{(wallet?.is_premium === true || wallet?.is_premium === 1) && (
												<ImageC
													className="ml-auto"
													alt="logo"
													src={'/images/premium-label.svg'}
													height={20}
													width={73}
												/>
											)}
										</ButtonC>
									);
								})}
					</div>
				</ConfigProvider>

				<ButtonBorder className={classes['btn-add']} onClick={onAddCreoWalletClick}>
					+ Add new CREO Wallet
				</ButtonBorder>
				<ButtonBorder className={classes['btn-add']} onClick={handleAddExternalWalletClick}>
					+ Add new external wallet
				</ButtonBorder>

				<Button
					className={classes['button-close']}
					icon={<ImageC src="/icons/icon-close-white.svg" alt="icon close" width={14} />}
					shape="circle"
					onClick={onCloseClick}
				/>
			</div>
		</Spinner>
	);
};

export default CardSelectWallet;
