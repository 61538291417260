import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useGetMaintenanceStatus } from 'src/hooks/useGeneral';

import Spinner from '../Spinner';

const MaintenanceChecker = () => {
	const router = useRouter();

	const { data, isFetching } = useGetMaintenanceStatus();
	const isMaintenance =
		data?.data?.data?.maintenanceStatus === true || data?.data?.data?.maintenanceStatus === 'true';

	useEffect(() => {
		if (isMaintenance && router.pathname !== '/') {
			router.replace('/');
		}
	}, [isMaintenance, router.pathname]);

	if (
		isFetching ||
		(router.pathname !== '/' && (isMaintenance === undefined || isMaintenance === true))
	) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					zIndex: 9999,
					width: '100vw',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#060606',
				}}
			>
				<Spinner />
			</div>
		);
	} else {
		return null;
	}
};

export default MaintenanceChecker;
