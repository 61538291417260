import { useEffect, useState } from 'react';

import { useModalWalletContext } from 'src/context/modal';
import useAuthAction from 'src/hooks/useAuthAction';

import CardAddWallet from '../CardWallet/CardAddWallet';
import CardAddWalletByEmail from '../CardWallet/CardAddWalletByEmail';
import CardAddWalletByTelegram from '../CardWallet/CardAddWalletByTelegram';
import CardAddWalletByWhatsapp from '../CardWallet/CardAddWalletByWhatsapp';
import CardReloginWalletByEmail from '../CardWallet/CardReloginWalletByEmail';
import CardReloginWalletByTelegram from '../CardWallet/CardReloginWalletByTelegram';
import CardReloginWalletByWhatsapp from '../CardWallet/CardReloginWalletByWhatsapp';
import CardSelectWallet from '../CardWallet/CardSelectWallet';
import ModalC from '../Modal';

import classes from './index.module.less';

export interface IReloginData {
	creo_id: string;
	verify_token: string;
	wallet_address: string;
	phone_number: string;
	email: string;
}

const ModalWallet = () => {
	const { toggleModalExternalWallet } = useAuthAction();

	const {
		initType,
		type,
		setType,
		isModalWalletOpen: open,
		closeModalWallet,
	} = useModalWalletContext();

	const [reloginData, setReloginData] = useState<IReloginData | null>(null);

	// const [isLoading, setIsLoading] = useState<boolean>(false);
	const isLoading = false;

	useEffect(() => {
		if (open && initType) {
			setType(initType);
		} else if (!open) {
			setType('select-wallet');
		}

		toggleModalExternalWallet(open ?? false, null);
	}, [open]);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				if (type === 'add-by-email' || type === 'add-by-telegram' || type === 'add-by-whatsapp') {
					document.querySelector(`.${classes.modal}`)?.querySelectorAll('input')?.[1]?.focus();
				}
			}, 100);
		}
	}, [open, type]);

	const onClose = () => {
		closeModalWallet();

		// if (initType && type === initType) {
		// closeModalWallet();
		// } else if (type === 'select-wallet' || type === 'add-wallet') {
		// closeModalWallet();
		// } else {
		// setType('add-wallet');
		// }
	};

	const onBack = () => {
		if (type === 'add-wallet') {
			setType('select-wallet');
		} else if (
			type === 'add-by-email' ||
			type === 'add-by-telegram' ||
			type === 'add-by-whatsapp'
		) {
			setType('add-wallet');
		} else if (
			type === 'relogin-email' ||
			type === 'relogin-whatsapp' ||
			type === 'relogin-telegram'
		) {
			setType('select-wallet');
		}
	};

	const onSuccess = () => {
		setType('select-wallet');
	};

	const handleRequestRelogin = (walletType: string, data?: IReloginData) => {
		// console.log({ 'verify set current wallet': walletAddress });

		setReloginData(data ?? null);

		if (walletType === 'email') {
			setType('relogin-email');
		} else if (walletType === 'whatsapp') {
			setType('relogin-whatsapp');
		} else if (walletType === 'telegram') {
			// setType('relogin-telegram');
		}
	};

	return (
		<ModalC
			className={classes.modal}
			open={open}
			onClose={onClose}
			centered
			showCloseIcon={false}
			closeOnOverlayClick={
				isLoading || (type !== 'select-wallet' && type !== 'add-wallet') ? false : true
			}
		>
			<div className={classes['wrap-auth']}>
				{/* {type === 'add-wallet' && (
					<>
						<Button
							className={classes['button-back']}
							icon={<ImageC src="/icons/icon-back-white.svg" alt="icon back" width={16} />}
							shape="circle"
							onClick={onBack}
						/>
						<Button
							className={classes['button-close']}
							icon={<ImageC src="/icons/icon-close-white.svg" alt="icon back" width={16} />}
							shape="circle"
							onClick={onClose}
							disabled={isLoading}
						/>
					</>
				)} */}

				{type === 'select-wallet' && (
					<CardSelectWallet
						onCloseClick={onClose}
						onRequestRelogin={handleRequestRelogin}
						onAddCreoWalletClick={() => setType('add-wallet')}
						onAddExternalWalletClick={() => {}}
					/>
				)}
				{type === 'add-wallet' && (
					<CardAddWallet
						onBack={initType !== type ? onBack : undefined}
						onClose={onClose}
						onLoginEmailClick={() => setType('add-by-email')}
						onLoginTelegramClick={() => setType('add-by-telegram')}
						onLoginWhatsappClick={() => setType('add-by-whatsapp')}
						onSuccess={() => {
							setType('select-wallet');
						}}
					/>
				)}
				{type === 'add-by-email' && (
					<CardAddWalletByEmail onBack={onBack} onClose={onClose} onSuccess={onSuccess} />
				)}
				{type === 'add-by-whatsapp' && (
					<CardAddWalletByWhatsapp onBack={onBack} onClose={onClose} onSuccess={onSuccess} />
				)}
				{type === 'add-by-telegram' && (
					<CardAddWalletByTelegram onBack={onBack} onClose={onClose} onSuccess={onSuccess} />
				)}
				{type === 'relogin-email' && (
					<CardReloginWalletByEmail
						reloginData={reloginData}
						onBack={onBack}
						onClose={onClose}
						onSuccess={onSuccess}
					/>
				)}
				{type === 'relogin-whatsapp' && (
					<CardReloginWalletByWhatsapp
						reloginData={reloginData}
						onBack={onBack}
						onClose={onClose}
						onSuccess={onSuccess}
					/>
				)}
				{type === 'relogin-telegram' && (
					<CardReloginWalletByTelegram
						reloginData={reloginData}
						onBack={onBack}
						onClose={onClose}
						onSuccess={onSuccess}
					/>
				)}
			</div>
		</ModalC>
	);
};

export default ModalWallet;
