import { useEffect, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useLoginTelegram } from 'src/hooks';

import Button from '../Button';
import ImageC from '../Image';
import Input from '../Input';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	// onSignUpClick?: () => void;
	onBack?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardAddWalletByTelegram = ({
	// onSignUpClick,
	onBack,
	onClose,
	updateLoadingState,
}: Props) => {
	// const { setUser } = useAuthReducer();

	const {
		// mutate: login,
		// data: dataLogin,
		isLoading: isLoadingLogin,
	} = useLoginTelegram();

	const isLoading = isLoadingLogin;

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

	const [formData, setFormData] = useState<{
		code: string;
		number: string;
	}>({
		code: '+62',
		number: '',
	});

	const validateFormData = (data: any) => {
		if (
			data == null ||
			Object.values(data ?? [])?.find((val: any) => val.length < 3) !== undefined
		) {
			return false;
		}

		return true;
	};

	const handleFormInputChange = (e: any) => {
		const name = e.target.name;
		let value = e.target.value;
		// if (name === 'username') {
		// value = value.replace(/[^0-9a-z]/gi, '');
		// }
		// console.log(value);
		if (name === 'code') {
			if (value.length > 5) {
				return;
			} else {
				// Removing every non-digit character except a plus (+) sign
				value = value.replace(/[^\d\+]+/g, '').replace(/(.)\++/g, '$1');
			}
		} else if (name === 'number') {
			if (value.length > 15) {
				return;
			}
		}
		setFormData({ ...formData, [name]: value });
	};

	const handleConnectClick = (e: any) => {
		e.preventDefault();

		// const phoneNumber = `${formData.code}${formData.number}`.replaceAll('+', '');

		// login(
		// { phone_number: phoneNumber },
		// {
		// onSuccess: (res: any) => {
		// if (res.status === 200) {
		// // start checking if user has confirm in telegram
		// setStep(2);
		// } else {
		// notify(res?.data?.message ?? 'Failed to login telegram', 'error');
		// }
		// },
		// },
		// );
	};

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	useEffect(() => {
		setButtonDisabled(true);
	}, [step]);

	useEffect(() => {
		if (validateFormData(formData)) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [formData]);

	return (
		<CardAuth>
			<div className={classes.header}>
				<ImageC
					alt="icon back"
					className="cursor-pointer"
					src="/icons/icon-back-white.svg"
					width={16}
					height={16}
					onClick={() => {
						if (step > 1) {
							setStep(step - 1);
						} else {
							onBack?.();
						}
					}}
				/>
				<ImageC
					alt="icon close"
					className="cursor-pointer"
					src="/icons/icon-close-white.svg"
					width={16}
					height={16}
					onClick={onClose}
				/>
			</div>

			<h1 className={classes.title}>Add new CREO Wallet</h1>

			{step === 1 && (
				<>
					<form className={classNames(classes.form, 'pt-4 mb-0')} onSubmit={handleConnectClick}>
						<Row gutter={[30, 12]}>
							<Col span={24}>
								<div className={classes['phone-number']}>
									<div
										className={classes.code}
										style={{
											width: `calc(${formData?.code?.toString()?.length ?? 0}ch + 38px)`,
										}}
									>
										<Input
											name="code"
											placeholder="+62"
											// defaultValue={'+62'}
											value={formData.code}
											onChange={handleFormInputChange}
										/>
									</div>
									<div className={classes.line}></div>
									<div className={classes.number}>
										<Input
											className={classes.number}
											type="number"
											name="number"
											placeholder=""
											value={formData.number}
											onChange={handleFormInputChange}
										/>
									</div>
								</div>
							</Col>
						</Row>
						<div className={classNames(classes['create-account'], 'mt-4 text-center')}>
							Enter your Telegram number above and we will send you the verification number
						</div>
						<Button
							className={classNames(classes['submit-btn'], 'w-100 mb-4')}
							type="submit"
							loading={isLoading}
							disabled={buttonDisabled}
							style={{ marginTop: 73 }}
						>
							Send Verification
						</Button>
						{/* <div className={classNames(classes['create-account'], 'mt-0')}>
							<span>Lost your number?</span>
							<a onClick={() => onSignUpClick?.()}>Recover Account</a>
						</div> */}
						{/* <div className={classes.agreement}>
							By signing up you are agreeing to <br />
							our <a>Terms of Use</a> and our <a>Privacy Policy</a>
						</div> */}
					</form>
				</>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'], 'pt-2')}
					// onSubmit={handleVerifyOtpClick}
				>
					<div className={classNames(classes.label)}>
						<div>
							Waiting for confirmation in Telegram <br />
							{formData.code + formData.number}
						</div>
					</div>
					<div className="w-100 mt-5 pt-5 d-flex justify-content-center">
						<Spinner className="" color="#FFFFFFCC" width={7} size={63} />
					</div>
				</form>
			)}
		</CardAuth>
	);
};

export default CardAddWalletByTelegram;
