import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import classNames from 'classnames';

import { useWeb3Context } from 'src/context/web3';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { useSetCurrentWallet, useVerifySetCurrentWallet } from 'src/hooks/useWallet';
import { getClipboardText } from 'src/utils/func';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import ImageC from '../Image';
import InputOTP from '../InputOTP';
import { IReloginData } from '../ModalWallet';

import classes from './index.module.less';

interface Props {
	onForgotPasswordClick?: () => void;
	onBack?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
	email?: string;
	reloginData: IReloginData | null;
}

const CardReloginWalletByEmail = ({
	// onForgotPasswordClick,
	onBack,
	onClose,
	onSuccess,
	updateLoadingState,
	reloginData,
}: Props) => {
	const { clearUserProfile } = useAuthReducer();
	const { disconnect } = useWeb3Context();

	const {
		mutate: resendOtp,
		data: dataResendOtp,
		isLoading: isLoadingResendOtp,
	} = useSetCurrentWallet();
	const { mutate: verifyOtp, isLoading: isLoadingVerifyOtp } = useVerifySetCurrentWallet();

	const resResendOtp = dataResendOtp?.data?.data;

	const isLoading = isLoadingResendOtp || isLoadingVerifyOtp;

	const otpNumberOfDigits = 6;
	const otpExpiryTime = parseInt(process.env.NEXT_PUBLIC_OTP_EXPIRED_DURATION as string); // in seconds

	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));
	const [otpExpiredCountdown, setOtpExpiredCountdown] = useState<number>(0);

	const finalOtp = otp.join('');

	const otpExpiredCountdownRef = useRef(otpExpiryTime);

	let otpExpiredCountdownInterval: any = null;
	const stopOtpExpiredCountdown = () => {
		if (otpExpiredCountdownInterval !== null) {
			clearInterval(otpExpiredCountdownInterval);
			otpExpiredCountdownInterval = null;
		}
	};
	const startOtpExpiredCountdown = () => {
		stopOtpExpiredCountdown();

		otpExpiredCountdownRef.current = otpExpiryTime;
		setOtpExpiredCountdown(otpExpiredCountdownRef.current);

		otpExpiredCountdownInterval = setInterval(() => {
			if (otpExpiredCountdownRef.current === 0) {
				stopOtpExpiredCountdown();
				return;
			}
			otpExpiredCountdownRef.current = otpExpiredCountdownRef.current - 1;
			setOtpExpiredCountdown(otpExpiredCountdownRef.current);
		}, 1000);
	};

	const getCountdownTimer = () => {
		const min: string = Math.floor(otpExpiredCountdown / 60).toString();
		let sec: string = (otpExpiredCountdown % 60).toString();
		if (sec.length === 1) {
			sec = '0' + sec;
		}
		return min + ':' + sec;
	};

	const handlePasteFromClickboardClick = async () => {
		let clipboardText = await getClipboardText();
		clipboardText = clipboardText.replace(/\s/g, '');

		setOtp([
			clipboardText[0] ?? '',
			clipboardText[1] ?? '',
			clipboardText[2] ?? '',
			clipboardText[3] ?? '',
			clipboardText[4] ?? '',
			clipboardText[5] ?? '',
		]);
	};

	const handleResendOtpClick = () => {
		resendOtp(
			{ wallet_address: reloginData?.wallet_address ?? '' },
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						startOtpExpiredCountdown();
					} else {
						notify(res?.data?.message ?? 'Failed to resend otp', 'error');
					}
				},
			},
		);
	};

	const handleVerifyOtpClick = (e: any) => {
		e.preventDefault();

		if (finalOtp.length === otpNumberOfDigits) {
			const verifyToken = resResendOtp?.verify_token ?? reloginData?.verify_token;

			verifyOtp(
				{
					verify_token: verifyToken,
					wallet_address: reloginData?.wallet_address ?? '',
					otp: finalOtp,
				},
				{
					onSuccess: (res: any) => {
						if (res.status === 200) {
							notify('Successfully switch wallet');

							clearUserProfile();

							onSuccess?.();

							disconnect();
						} else {
							notify(res?.data?.message ?? 'Failed to switch wallet', 'error');
						}
					},
				},
			);
		}
	};

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	useEffect(() => {
		startOtpExpiredCountdown();
	}, []);

	useEffect(() => {
		if (finalOtp.length === otpNumberOfDigits) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [finalOtp]);

	return (
		<CardAuth showCreoplayImage={false}>
			<div className={classes.header}>
				<ImageC
					alt="icon back"
					className="cursor-pointer"
					src="/icons/icon-back-white.svg"
					width={16}
					height={16}
					onClick={onBack}
				/>
				<ImageC
					alt="icon close"
					className="ml-auto cursor-pointer"
					src="/icons/icon-close-white.svg"
					width={16}
					height={16}
					onClick={onClose}
				/>
			</div>

			<h1 className={classes.title}>Wallet Login</h1>

			<form
				className={classNames(classes.form, classes['form-otp'], 'mt-3')}
				onSubmit={handleVerifyOtpClick}
			>
				<InputOTP otp={otp} setOtp={setOtp} />

				<div className={classNames(classes.label, 'mt-3')}>
					<div>
						You have been inactive for 7 or more days. <br />
						Please enter OTP code that has been sent to
						<br />
						{reloginData?.email}
					</div>
				</div>
				{finalOtp.length === otpNumberOfDigits ? (
					<Button
						style={{ marginTop: 202 }}
						className={classNames('w-100', classes['submit-btn'], 'mb-4')}
						type="submit"
						loading={isLoading}
						disabled={buttonDisabled}
					>
						Continue
					</Button>
				) : (
					<Button
						style={{ marginTop: 202 }}
						className={classNames('w-100', classes['submit-btn'], 'mb-4')}
						onClick={handlePasteFromClickboardClick}
					>
						Paste from Clipboard
					</Button>
				)}

				<div className={classNames(classes.label, 'fw-400')}>
					<div>
						Expires in <span className="fw-500">{getCountdownTimer()}</span>
					</div>
					<a
						className={classNames({ disabled: isLoadingResendOtp || otpExpiredCountdown !== 0 })}
						onClick={handleResendOtpClick}
					>
						Resend code
					</a>
				</div>
			</form>
		</CardAuth>
	);
};

export default CardReloginWalletByEmail;
