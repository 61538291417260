import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthState {
	user: IUser | null;
	modalAuthOpen: boolean;
	modalExternalWalletOpen: boolean;
	modalExternalWalletState: 'connect' | 'add' | 'switch' | null;
	switchWalletAddress: string | null;
}

import { IUser } from 'src/hooks/reducers/useAuthReducer';

export const authSlice = createSlice({
	name: 'authSlice',
	initialState: {
		user: null,
		modalAuthOpen: false,
		modalExternalWalletOpen: false,
		modalExternalWalletState: null,
		switchWalletAddress: null,
	} as IAuthState,
	reducers: {
		setAuthUserData(state: IAuthState, { payload }: PayloadAction<{ user: IAuthState['user'] }>) {
			Object.assign(state, payload);
		},
		setModalAuthOpen(state: IAuthState, { payload }: PayloadAction<IAuthState['modalAuthOpen']>) {
			state.modalAuthOpen = payload;
		},
		setModalExternalWalletOpen(
			state: IAuthState,
			{ payload }: PayloadAction<IAuthState['modalExternalWalletOpen']>,
		) {
			state.modalExternalWalletOpen = payload;
		},
		setModalExternalWalletState(
			state: IAuthState,
			{ payload }: PayloadAction<IAuthState['modalExternalWalletState']>,
		) {
			state.modalExternalWalletState = payload;
		},
		setSwitchWalletAddress(
			state: IAuthState,
			{ payload }: PayloadAction<IAuthState['switchWalletAddress']>,
		) {
			state.switchWalletAddress = payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setAuthUserData,
	setModalAuthOpen,
	setModalExternalWalletOpen,
	setModalExternalWalletState,
	setSwitchWalletAddress,
} = authSlice.actions;

export default authSlice;
