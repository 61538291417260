export const isVideoUrl = (url: string) => {
	const indexOfTheAssetType = url.split('.').length - 1; // because the extension will always be at the end
	const assetType = url.split('.')[indexOfTheAssetType].toUpperCase();
	const isTheAssetVideoType = [
		'WEBM',
		'MPG',
		'MPEG',
		'MPE',
		'MP4',
		'M4P',
		'M4V',
		'AVI',
		'WMV',
		'MOV',
	].includes(assetType);
	return isTheAssetVideoType;
};

export const nowTime = () => {
	const date = new Date().getTime();
	return date / 1000;
};

export const getClipboardText = async () => {
	let text = await navigator.clipboard.readText();
	return text ?? '';
};

export function checkTypeMarketplace(type: string) {
	return type !== 'chest' && type !== 'in-game' && type !== 'nft';
}
